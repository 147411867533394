
.videos {
    padding: 1rem;
    background: #000000;
}

.video {
padding: 4rem;
background: #000000;
}

h1 {
text-align: center;
}

.video_container {
display: flex;
flex-flow: column;
align-items: center;
/* max-width: 1120px; */
width: 100%;
margin: 0 auto;
}

.video_item__location {
color: #ffffff;
font-size: 10px;
line-height: 12px;
text-align: left;
}

.video_wrapper {
position: relative;
width: 90%;
height: 50%;
margin: 50px 0 45px;
}

.video_items {
margin-bottom: 24px;
}

.video_item {
/* display: flex; */
flex-flow: row;
flex: .5;
margin: 0 2rem;
margin-bottom: 50px;
border-radius: 10px;
height: 20%;
}

.video_item__link {
display: flex;
flex-flow: row;
width: 100%;
height: 50%;
box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
border-radius: 10px;
overflow: hidden;
text-decoration: none;
}

.video_item__pic-wrap {
position: inherit;
width: 50%;
height: 200px;
padding: 10px;
overflow: hidden;
}

.fade-img {
animation-name: fade-img;
animation-duration: 2s;
}

/* .video_item__pic-wrap::after {
content: attr(data-category);
position: absolute;
bottom: 0;
margin-left: 10px;
padding: 6px 8px;
max-width: calc((100%) - 60px);
font-size: 12px;
font-weight: 700;
color: #fff;
background-color: #1f98f4;
box-sizing: border-box;
} */

.video_item__img  {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
border-radius: 5px;
display: block;
width: 100%;
max-width: 30%;
height: 100%;
max-height: 100%;
object-fit: contain;
transition: all 0.2s linear;
}

.video_item__img:hover {
    transform: scale(1.1);
}

.overlay_icon {
  color: #fff;
	position: absolute;
  display: block;
  left: 30px;
  top: 20px;
  transform: scale(3);
}

.videodescription {
display: flex;
flex-flow: row;
width: 100%;
}

.video_item__info {
padding: 10px 10px 10px;
}



.videooption {
display: flex;
color: #fff;
padding: 10px 10px 10px;
font-size: 18px;
}

.video_item__text {
color: #ffffff;
font-size: 15px;
line-height: 24px;
}

.video_item__para {
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;
  }

@media only screen and (min-width: 1200px) {
.content__blog__container {
  width: 84%;
}
}

@media only screen and (min-width: 1024px) {
.video_items {
  /* display: flex; */
}
}

@media only screen and (max-width: 1024px) {
.video_item {
  margin-bottom: 2rem;
}
}